import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Projects from "../containers/projects/projects";

const ProjectsPage = (props: any) => {
    const { data } = props;

    return (
        <Layout>
            <SEO
                title={data.site.siteMetadata.title}
                description={data.site.siteMetadata.description}
            />
            <Projects />
        </Layout>
    );
};

export default ProjectsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
