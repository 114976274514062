import * as React from 'react';
interface ProjectsProps {}

const Projects: React.FunctionComponent<ProjectsProps> = () => {
  return (
    <div>
      {/*<p>Projects</p>*/}
    </div>
  );
};

export default Projects;
